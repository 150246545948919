import styled from "@emotion/styled"
import React from "react"
import { fonts, device, colors } from "../layout/GlobalStyles"
import goodHealth from "../../images/programs/good-health.svg"
import spacesLogo from "../../images/programs/logo-spaces.svg"
import mlifeLogo from "../../images/logos/logo-regular.svg"
import sdgLogo from "../../images/programs/logo-un.svg"

const BioContainer = styled.section`
  padding-bottom: 72px;

  .line {
    width: 1px;
    height: 80px;
    background: black;
  }
  @media ${device.laptop} {
    padding-bottom: 200px;
    padding-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const LeftPanel = styled.div`
  padding: 72px 0px;
  h2 {
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1.88px;
    margin: 0em 0px 10px 00px;
    line-height: 65px;
  }
  p {
    margin: 0 0px 30px 0px;
    color: ${colors.primary2};
    font-size: 40px;
    letter-spacing: -1.25px;
    line-height: 48px;
    font-family: ${fonts.secondary};
  }
  span {
    font-weight: 500;
    position: relative;
    top: 10px;
    font-size: 18px;
    letter-spacing: -0.56px;
    line-height: 27px;
    font-family: ${fonts.primary};
  }

  div {
    margin: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      width: 1px;
      height: 80px;
      position: relative;
      top: 10px;

      background: black;
    }
    .logo {
      width: 140.3px;
    }
  }
  @media ${device.laptop} {
    p {
      max-width: 418px;
    }
    margin-bottom: 70px;
    div {
      margin: -5px 0px 15px;
    }

    min-width: 463px;
  }
  p + div {
    width: 363px;
  }
`

const RightPanel = styled.div`
  div {
    display: flex;
  }
  p {
    font-size: 22px;
  }
  .line {
    height: 80px;
    width: 1px;
    background: ${colors.black};
  }
  .logo-container {
    justify-content: space-between;
  }
  @media ${device.laptop} {
    margin-top: 0px;
    max-width: 553px;
    padding: 0px 0px 0px;
    position: relative;
    top: 65px;
    div {
      width: 326px;
      margin-top: 0px;
    }
  }
  p:first-of-type {
    margin-top: 50px;
  }
  p + p {
    margin-top: 40px;
    margin-bottom: 0;
  }
`

export const SpacesBio = () => {
  return (
    <BioContainer className="container">
      <LeftPanel>
        <h2>Our Goal</h2>
        <p>Support mental health.</p>
        <div>
          <img className="logo" src={mlifeLogo} alt="mlife logo" />
          <div></div>
          <img src={sdgLogo} alt="SDG logo" />
        </div>
        <span>MLIFE support(s) the Sustainable Development Goals. </span>
      </LeftPanel>
      <RightPanel>
        <div className="logo-container">
          <img className="program-logo" src={spacesLogo} alt="spaces logo" />
          <div className="line"></div>
          <img
            src={goodHealth}
            className="box-image"
            alt="Good health and well-being"
          />
        </div>
        <section>
          <p>
            Suicide is the fourth leading cause of death among 15-29-year-olds,
            according to the World health organization. Most mental health
            conditions are treatable and relatively low-cost. Communities living
            on the margins have no access to care.
          </p>
          <p>
            MLIFE's Spaces of Grace program creates awareness about mental
            health to reduce stigma and increase understanding. We create spaces
            of healing from shame and things we are afraid to share with others
            because we believe psychosocial safety promotes good health and
            wellbeing.
          </p>
        </section>
      </RightPanel>
    </BioContainer>
  )
}
